import axios from "axios";

const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Medium": "web",
};

export const axiosInstance =
    axios.create({
        baseURL: import.meta.env.VITE_APP_BASE_API_URL,
        timeout: 1000 * 60,
        headers,
    });